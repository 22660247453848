<template>
  <div>
    <BTable
      class="table-bordered"
      :items="dkLegalContact.results"
      :fields="fields2"
      show-empty
      responsive
    >
      <template #empty>
        <empty />
      </template>

      <template #cell(pay_his)="props">
        <div v-if="props.item.BASIS">
          <div
            v-for="(e, index) in props.item.BASIS.PAY_HISTORY"
            :key="index"
          >
            <ul
              class="d-flex align-items-center justify-content-between mb-0"
            >
              <li style="font-size: 11px">
                {{ e.PAY_DATE }}
              </li>
              <small class="ml-50">
                {{ $_moneyFormatter(e.PAY_AMOUNT) }},
              </small>
            </ul>
          </div>
        </div>
      </template>

      <template #cell(EMAIL)="props">
        <div
          v-for="(item, index) in props.item.EMAIL"
          :key="index"
          @click.stop
        >
          <a
            :href="'mailto:' + item"
            class="ml-50 text-right"
            v-html="item"
          />
        </div>
      </template>

      <template #cell(FULL_NAME)="data">
        <div
          v-if="data.item.FULL_NAME"
          class="text-primary cursor-pointer"
          @click="collector(data)"
        >
          {{ data.item.FULL_NAME }}
        </div>
      </template>
    </BTable>

    <div class="d-flex justify-content-between flex-wrap">
      <div class="d-flex align-items-center mb-0">
        <span class="text-nowrap mr-1">Показать по</span>
        <b-form-select
          v-model="params.page_size"
          :options="[5, 10, 20]"
          class="mx-1"
          @change="refresh"
        />
        <span class="ml-1 text-nowrap"> строк( {{ dkLegalContact.count }} )</span>
      </div>
      <b-pagination
        v-model="params.page"
        :total-rows="dkLegalContact.count"
        :per-page="params.page_size"
        first-number
        last-number
        align="right"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
        @change="changePage"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Empty from '@components/empty.vue'
import {
  BTable, BFormSelect, BPagination,
} from 'bootstrap-vue'

export default {
  components: {
    Empty,
    BTable,
    BFormSelect,
    BPagination,
  },
  props: {
    companyId: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      fields2: [
        {
          key: 'id',
          label: '№',
          sortable: false,
        },
        {
          key: 'FULL_NAME',
          label: 'Названия',
          sortable: false,
        },
        {
          key: 'EMAIL',
          label: 'Электронная почта',
          sortable: false,
        },
      ],
      params: {
        page: 1,
        page_size: 10,
      },
    }
  },
  computed: {
    ...mapState('legalPortfolios', ['dkLegalContact']),
    ...mapState('legalPortfoliosCollector', ['historyTimeLine']),
    ...mapState('collectorDetail', ['active']),
  },
  methods: {
    ...mapActions('legalPortfolios', ['FETCH_DK_LEGAL_CONTACT']),
    ...mapMutations('legalPortfoliosCollector', ['SET_ACTIVE', 'SET_CALL']),

    refresh() {
      this.FETCH_DK_LEGAL_CONTACT({
        company_id: this.companyId,
        ...this.params,
      })
    },

    collector(data) {
      this.historyTimeLine.results = []
      this.SET_ACTIVE(3)
      this.SET_CALL(false)
      this.$router.push(`/legal-collector/${data.item.id}`)
    },

    changePage(page) {
      this.params.page = page

      this.refresh()
    },
  },
}
</script>
