<template>
  <b-modal
    id="AddEditModal"
    title="Портфели"
    centered
    no-close-on-backdrop
    ok-title="Сохранить"
    cancel-variant="outline-secondary"
    cancel-title="Отмена"
    responsive
    @show="openShowModal"
    @ok="onSubmit"
  >
    <validation-observer ref="claimantsValidation">
      <b-form-group label="Взыскатель">
        <validation-provider
          #default="{ errors }"
          name="Взыскатель"
          rules="required"
        >
          <b-form-input
            v-model="value.COMPANY_NAME"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!--      <b-form-group label="Общая сумма">-->
      <!--        &lt;!&ndash;        <validation-provider&ndash;&gt;-->
      <!--        &lt;!&ndash;          #default="{ errors }"&ndash;&gt;-->
      <!--        &lt;!&ndash;          name="Общая сумма"&ndash;&gt;-->
      <!--        &lt;!&ndash;          rules="required"&ndash;&gt;-->
      <!--        &lt;!&ndash;        >&ndash;&gt;-->
      <!--        <b-form-input-->
      <!--          v-model="value.total_sum"-->
      <!--          type="number"-->
      <!--        />-->
      <!--      &lt;!&ndash;          <small class="text-danger">{{ errors[0] }}</small>&ndash;&gt;-->
      <!--      &lt;!&ndash;        </validation-provider>&ndash;&gt;-->
      <!--      </b-form-group>-->

      <b-form-group
        label="Исполнители"
      >
        <!--        <validation-provider-->
        <!--          #default="{ errors }"-->
        <!--          name="Исполнители"-->
        <!--          rules="required"-->
        <!--        >-->
        <v-select
          v-model="users"
          :get-option-label="(el) => el.first_name + ' ' + el.last_name"
          multiple
          :options="userList.results"
        >
          <template #no-options>
            К сожалению, нет подходящих вариантов
          </template>
        </v-select>
        <!--          <small class="text-danger">{{ errors[0] }}</small>-->
        <!--        </validation-provider>-->
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import { BFormGroup, BFormInput, BModal } from 'bootstrap-vue'
import VSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
import { required } from '@validations'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    VSelect,
  },
  props: {
    modalVal: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    // total_sum: null,
    return {
      required,
      value: {
        COMPANY_NAME: null,
      },
      users: [],
    }
  },
  computed: {
    ...mapState('users', ['userList']),
  },
  methods: {
    ...mapActions('portfolios', ['CREATE_CLAIMANT_TYPE', 'UPDATE_CLAIMANT_TYPE']),
    ...mapActions('users', ['FETCH_ALL_USERS']),

    onSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()

      this.$refs.claimantsValidation.validate()
        .then(success => {
          if (success) {
            const claimantTypeData = {
              users: this.users.map(e => e.id),
              ...this.value,
            }

            const updateClaimantType = this.modalVal.id
              ? this.UPDATE_CLAIMANT_TYPE({
                id: this.modalVal.COMPANY_ID,
                data: claimantTypeData,
              })
              : this.CREATE_CLAIMANT_TYPE(claimantTypeData)

            updateClaimantType.then(() => {
              this.$bvModal.hide('AddEditModal')
              this.value = {}
              this.$emit('refresh')
            })
          }
        })
    },

    openShowModal() {
      if (this.modalVal.id) {
        // this.value.total_sum = this.modalVal.total_sum
        this.value.COMPANY_NAME = this.modalVal.COMPANY_NAME
        this.users = this.modalVal.users
      } else {
        this.value = { COMPANY_NAME: null, total_sum: null }
        this.users = []
      }

      this.FETCH_ALL_USERS({ page_size: 500, role_unique_name: 'dk' })
    },
  },
}
</script>
